<style lang="scss">
@import "~@/assets/css/var";

.compare {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 25px 60px;


    .el-radio-group {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .el-select {
        width: 100%;
    }

    .condition {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        .el-link {
            font-size: 12px;
        }

        .el-select {
            .el-select__caret:first-child::before {
                content: "\e6e1";
            }

            .is-focus {
                .el-select__caret:first-child {
                    transform: rotateZ(0deg);
                }
            }
        }

        .el-tag {
            margin-right: 10px;
        }
    }


}
</style>

<template>
    <div class="compare" v-if="!$help.token.get()">
        <ts-error></ts-error>
    </div>
    <div class="compare" v-else v-loading="loading">
        <ts-error v-if="noAuth" style="margin-top: 150px;"></ts-error>
        <template v-else>
            <div style="display: flex;align-items: center">
                <div style="flex: 1">
                    <el-radio-group v-model="active" size="normal">
                        <el-radio-button label="drug">药品</el-radio-button>
                        <el-radio-button label="spec">药品+规格</el-radio-button>
                        <el-radio-button label="corp">药品+企业+规格</el-radio-button>
                    </el-radio-group>
                </div>
                <!-- <el-popover v-if="active === 'drug'" placement="right-start" width="300" trigger="hover">
                    <i slot="reference" class="el-icon-info" style="color: #45d75f;font-size: 18px;"></i>
                    <i></i>
                    <p class="through-page-tips" style="white-space: pre-wrap;">
                        此页面仅可选择第1个药品同用途的产品，例如选择“口服”，则后面药品仅可选择口服药对比
                    </p>
                </el-popover> -->
                <el-button style="margin-left: 10px;" size="medium" type="primary" @click="openPDF">EXCEL导出</el-button>
            </div>

            <template v-if="loaded">
                <drug-search v-if="active === 'drug'" ref="drugSearch" :visit-id="visitId"
                    :initDrugs="drugs"></drug-search>
                <spec-search v-if="active === 'spec'" ref="specSearch" :visit-id="visitId"
                    :initDrugs="drugs"></spec-search>
                <corp-search v-if="active === 'corp'" ref="corpSearch" :visit-id="visitId"
                    :initDrugs="drugs"></corp-search>
            </template>
        </template>
    </div>
</template>

<script>
import DrugSearch from './drugCompare/DrugSearch.vue';
import SpecSearch from './drugCompare/SpecSearch.vue';
import CorpSearch from './drugCompare/CorpSearch.vue';
// import domtoimage from 'dom-to-image'
export default {
    components: {
        DrugSearch,
        SpecSearch,
        CorpSearch,
    },
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',

            loading: false,
            loaded: false,
            active: 'drug',
            drugs: [],
            noAuth: false,
        }
    },
    watch: {
        active: {
            immediate: true,
            handler() {
                this.visitId = new Date().getTime()
                this.pageId = this.active
                switch (this.active) {
                    case 'drug':
                        this.pageName = '药品对比'
                        break
                    case 'spec':
                        this.pageName = '药品+规格对比'
                        break
                    case 'corp':
                        this.pageName = '药品+企业+规格对比'
                        break
                }

                this.$help.socket.send(this)
            }
        }
    },
    created() {
        this.drugSearch('')
    },
    methods: {
        drugSearch(keyword = '') {
            this.loading = true
            this.$api.get('report/drugContrast/queryAimBaseDrugsListByKeyword', {
                keyword: keyword,
            })
                .then(res => {
                    if (res.success) {
                        this.drugs = res.data
                        this.loaded = true
                    } else {
                        this.noAuth = true
                        return false
                    }

                })
                .finally(() => {
                    this.loading = false
                })
        },

        openPDF() {
            if (this.active === 'drug') {
                this.$refs.drugSearch && this.$refs.drugSearch.openPDF()
            } else if (this.active === 'spec') {
                this.$refs.specSearch && this.$refs.specSearch.openPDF()
            } else if (this.active === 'corp') {
                this.$refs.corpSearch && this.$refs.corpSearch.openPDF()
            }

        }
    },
}
</script>